import { IconProps } from "./IconProps";

function LightbulbIcon({
	width = "24",
	height = "24",
	color = "currentColor",
}: IconProps) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11 0C11.5523 0 12 0.447715 12 1V2C12 2.55228 11.5523 3 11 3C10.4477 3 10 2.55228 10 2V1C10 0.447715 10.4477 0 11 0ZM3.1928 3.1928C3.58332 2.80227 4.21648 2.80227 4.60701 3.1928L5.20711 3.79289C5.59763 4.18342 5.59763 4.81658 5.20711 5.20711C4.81658 5.59763 4.18342 5.59763 3.79289 5.20711L3.1928 4.60701C2.80227 4.21648 2.80227 3.58332 3.1928 3.1928ZM18.8074 3.19288C19.1979 3.58346 19.1978 4.21662 18.8073 4.6071L18.207 5.2072C17.8164 5.59767 17.1833 5.59759 16.7928 5.20702C16.4023 4.81644 16.4024 4.18328 16.793 3.7928L17.3932 3.19271C17.7838 2.80223 18.417 2.80231 18.8074 3.19288ZM11 6C8.23858 6 6 8.23858 6 11C6 13.7614 8.23858 16 11 16C13.7614 16 16 13.7614 16 11C16 8.23858 13.7614 6 11 6ZM4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 13.7924 16.3649 16.2029 14 17.3264V19C14 20.6569 12.6569 22 11 22C9.34315 22 8 20.6569 8 19V17.3264C5.63505 16.2029 4 13.7924 4 11ZM10 17.9291V19C10 19.5523 10.4477 20 11 20C11.5523 20 12 19.5523 12 19V17.9291C11.6734 17.9758 11.3395 18 11 18C10.6605 18 10.3266 17.9758 10 17.9291ZM0 11C0 10.4477 0.447715 10 1 10H2C2.55228 10 3 10.4477 3 11C3 11.5523 2.55228 12 2 12H1C0.447715 12 0 11.5523 0 11ZM19 11C19 10.4477 19.4477 10 20 10H21C21.5523 10 22 10.4477 22 11C22 11.5523 21.5523 12 21 12H20C19.4477 12 19 11.5523 19 11Z"
				fill={color}
			/>
		</svg>
	);
}
export default LightbulbIcon;
